import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_REQUEST = '[RoleUserDetails] Load Details Request',
  LOAD_FAILURE = '[RoleUserDetails] Load Details Failure',
  LOAD_SUCCESS = '[RoleUserDetails] Load Details Success',

  SET_ROLE_REQUEST = '[RoleUserDetails] Set Role Details Request',
  SET_ROLE_FAILURE = '[RoleUserDetails] Set Role Details Failure',
  SET_ROLE_SUCCESS = '[RoleUserDetails] Set Role Details Success',

  RESET_STATE = '[RoleUserDetails] RoleUserDetailsState',
}

export class SetRoleUserRequest implements Action {
  public readonly type = ActionTypes.SET_ROLE_REQUEST;
  constructor(
    public payload: {
      property_id: number;
      property_role_id: number;
      userId: number;
    },
  ) { }
}

export class SetRoleUserSuccess implements Action {
  public readonly type = ActionTypes.SET_ROLE_SUCCESS;
}

export class SetRoleUserFailure implements Action {
  public readonly type = ActionTypes.SET_ROLE_FAILURE;
  constructor(public payload: { error: Error }) { }
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertyId: number; userId: number }) { }
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) { }
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { item: any }) { }
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | SetRoleUserSuccess
  | SetRoleUserFailure
  | SetRoleUserRequest
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | ResetSuccessAction;
