import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { RoleUserDetailsService } from '@app/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as featureActions from './actions';

@Injectable()
export class RoleUserDetailsStoreEffects {
  constructor(
    private dataService: RoleUserDetailsService,
    private actions$: Actions,
    private _errorHandler: ErrorHandlerService,
  ) {}

  load$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) =>
        this.dataService
          .load(action.payload.propertyId, action.payload.userId)
          .pipe(
            map(
              ({ data }: any) =>
                new featureActions.LoadSuccessAction({
                  item: data,
                }),
            ),
            catchError((error) => {
              this._errorHandler.handle(error);
              return of(new featureActions.LoadFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  setRole$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SetRoleUserRequest>(
        featureActions.ActionTypes.SET_ROLE_REQUEST,
      ),
      switchMap((action: featureActions.SetRoleUserRequest) =>
        this.dataService
          .setRole(
            action.payload.property_id,
            action.payload.property_role_id,
            action.payload.userId,
          )
          .pipe(
            map(({ data }: any) => new featureActions.SetRoleUserSuccess()),
            catchError((error) => {
              this._errorHandler.handle(error);
              return of(new featureActions.SetRoleUserFailure({ error }));
            }),
          ),
      ),
    ),
  );
}
