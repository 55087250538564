import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const selectRoleUserDetailsState = createFeatureSelector<State>(
  'roleUserDetails',
);

export const selectRoleUserDetailsData = createSelector(
  selectRoleUserDetailsState,
  (state: State) => state.userRole,
);

export const selectRoleUserDetailsLoading = createSelector(
  selectRoleUserDetailsState,
  (state: State) => state.isLoading,
);
