import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SET_ROLE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.SET_ROLE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ActionTypes.SET_ROLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.LOAD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userRole: action.payload.item,
      };
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
