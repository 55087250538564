import { RoleUser } from '../../models';

export interface State {
  isLoading?: boolean;
  userRole: RoleUser;
}

export const initialState: State = {
  isLoading: false,
  userRole: null,
};
